




















import { defineComponent, PropType } from '@vue/composition-api'

import ExportHistoryTable from '@/components/organisms/h/page/export/ExportHistoryTable.vue'
import TitleAndManipulate from '@/components/organisms/h/TitleAndManipulate.vue'
import { ExportImportHistory } from '@/types/entities'
import {
  UiSAsyncExportStatusConnection,
  UiSAsyncExportStatus,
  // UiAsyncImportStatusConnection,
} from '@/types/generated/graphql'

export default defineComponent({
  components: { ExportHistoryTable, TitleAndManipulate },
  props: {
    type: {
      type: String as PropType<string>,
      default: '',
    },
    isLoadings: {
      // type: Object as PropType<ExportImportHistory>,
      // default: () => ({}),
      type: Boolean as PropType<boolean>,
      default: false,
    },
    uiSAsyncExportStatuses: {
      type: Object as PropType<UiSAsyncExportStatusConnection>,
    },
    perPage: {
      type: Number as PropType<number>,
    },
    selectedRadio: {
      type: String as PropType<string>,
    },
    userRadioVal: {
      type: String as PropType<string>,
    },
  },
  setup(props, context) {
    const changePageExportHistory = (v: number) => {
      context.emit('change-page-export-history', v)
    }

    const updateExportHistory = () => {
      context.emit('update-export-history')
    }

    const onExtractInfoDisplay = (item: UiSAsyncExportStatus) => {
      context.emit('extract-info-display', item)
    }

    const userVal = props.userRadioVal
    const selected = props.selectedRadio
    return {
      onExtractInfoDisplay,
      changePageExportHistory,
      updateExportHistory,
    }
  },
})
