










import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  props: {},
})
