






























































































import {
  defineComponent,
  getCurrentInstance,
  PropType,
  computed,
  ref,
} from '@vue/composition-api'
import { BIconDownload } from 'bootstrap-vue'
import Vue from 'vue'

import { ExportCondition } from '@/types/entities'
import {
  AsyncStatusTypeDb,
  Maybe,
  UiSAsyncExportStatus,
  UiSAsyncExportStatusConnection,
} from '@/types/generated/graphql'
import { asyncStatusTypeDbMapping } from '@/utils/constant'

Vue.component('BIconDownload', BIconDownload)

export default defineComponent({
  props: {
    items: {
      type: Object as PropType<UiSAsyncExportStatusConnection>,
    },
    perPage: {
      type: Number,
    },
    type: {
      type: String,
    },
    selected: {
      type: String as PropType<string>,
      default: '',
    },
    userVal: {
      type: String as PropType<string>,
      default: '',
    },
  },
  setup(props, context) {
    const instance = getCurrentInstance()
    if (!instance) {
      return
    }
    const { $i18n } = instance.proxy

    const currentPage = ref(1)

    const fields = [
      {
        key: 'index',
        label: 'No.',
        thClass: 'row-no',
        tdClass: 'row-no',
      },
      {
        key: 'screenName',
        label: $i18n.t('label.screenName'),
      },
      {
        key: 'createUserName',
        label: $i18n.t('label.operatedBy'),
        thClass: 'create-user-name',
      },
      {
        key: 'createDatetime',
        label: $i18n.t('label.requestedAt'),
        thClass: 'create-datetime',
      },
      {
        key: 'lastModifiedDatetime',
        label: $i18n.t('label.executedAt'),
        thClass: 'last-modified-datetime',
      },
      {
        key: 'status',
        label: $i18n.t('label.status'),
        thClass: 'status',
        tdClass: (value: string) =>
          isStatusError(value) ? 'status-error' : 'status',
      },
      {
        key: 'recordCountAll',
        label: $i18n.t('label.recordCountAll'),
        thClass: 'recordCountAll',
      },
      {
        key: 'url',
        label: $i18n.t('label.download'),
        thClass: 'url',
      },
      {
        key: 'graphqlQuery',
        label: $i18n.t('label.retrieveCondition'),
        tdClass: 'text-center',
      },
      {
        key: 'operationId',
        label: $i18n.t('label.operationId'),
        thClass: 'operation-id',
        tdClass: 'operation-id',
      },
    ]

    const onChangePage = (v: number) => {
      context.emit('change-page', v)
    }

    const onUpdateExportHistory = () => {
      currentPage.value = 1
      context.emit('update-export-history')
    }

    const displayTime = computed(() => {
      return (time: string) => {
        const d = new Date(time)
        const year = d.getFullYear()
        const month = (d.getMonth() + 1).toString().padStart(2, '0')
        const date = d.getDate().toString().padStart(2, '0')
        const hour = d.getHours().toString().padStart(2, '0')
        const minutes = d.getMinutes().toString().padStart(2, '0')
        return `${year}/${month}/${date} ${hour}:${minutes}`
      }
    })

    const displayParameter = computed(() => {
      return (parameter: string) => {
        const json = JSON.parse(parameter)
        // TODO: 他のパラメータを埋める
        type Entry = [string, string]
        const result: Entry[] = []
        if (json['store_cd']) {
          result.push(['storeCode', json['store_cd']])
        }
        if (result.length == 0) {
          return $i18n.t('label.noParameter')
        } else {
          return result
            .reduce((acc, entry) => {
              return acc + $i18n.t(`label.${entry[0]}`) + '=' + entry[1] + ','
            }, '')
            .replace(/,$/, '')
        }
      }
    })

    const displayScreenName = computed(() => {
      return (graphqlQuery: string): string => {
        const lines = graphqlQuery.split(`\n`)
        const type = lines.at(0)?.replace(/^\s*#\s*/, '') || ''
        return type
      }
    })

    const displayGraphqlQuery = computed(() => {
      return (graphqlQuery: string): string => {
        const lines = graphqlQuery.split(`\n`)
        const type = lines.at(0)?.replace(/^\s*#\s*/, '')
        const where = lines.at(1)?.replace(/^\s*#\s*/, '') || ''
        // const jsonString = where?.replace(/^\s*#\s*/, '') || ''
        try {
          const exportCondition = JSON.parse(where)
          console.log({
            graphqlQuery,
            type,
            where,
            exportCondition,
          })
          return exportCondition
        } catch (e) {
          return where
        }
        // const exportCondition = JSON.parse(jsonString)
        // console.log({ graphqlQuery, line, jsonString, exportCondition })
        // return jsonString
      }
      // const japanese = (condition: ExportCondition): string => {
      //   let result = ''
      //   result = result + '開始日: ' + condition.targetDate + ', '
      //   if (
      //     condition.storeCd !== undefined &&
      //     condition.storeCd !== null &&
      //     condition.storeCd !== ''
      //   ) {
      //     result = result + '店舗: ' + condition.storeCd + ', '
      //   }
      //   switch (condition.searchItemType) {
      //     case 0: // カテゴリ検索
      //       if (
      //         condition.category1 !== undefined &&
      //         condition.category1 !== null &&
      //         condition.category1.length !== 0
      //       ) {
      //         console.log({ category1: condition.category1 })
      //         result = result + 'カテゴリ: ' + condition.category1.join(',')
      //         if (
      //           condition.category2 !== undefined &&
      //           condition.category2 !== null &&
      //           condition.category2.length !== 0
      //         ) {
      //           result = result + ' / ' + condition.category2.join(',')
      //         }
      //         result = result + ', '
      //       }
      //       break
      //     case 1: // ゴンドラ検索
      //       result =
      //         result +
      //         'ゴンドラ: ' +
      //         condition.gondolaFrom +
      //         ' 〜 ' +
      //         condition.gondolaTo +
      //         ', '
      //       break
      //     case 2: // コード検索
      //       result =
      //         result +
      //         '商品コード: ' +
      //         condition.itemCodes
      //           ?.split('\n')
      //           .map((x) => x.trim())
      //           .join(', ') +
      //         ', '
      //     default:
      //       break
      //   }
      //   // 行の最後の ', ' を除去する
      //   return result.substring(0, result.length - 2)
      // }
      // return (graphqlQuery: string): string => {
      //   const line = graphqlQuery.split(`\n`).at(0)
      //   const jsonString = line?.replace(/^\s*#\s*/, '') || ''
      //   try {
      //     const exportCondition = JSON.parse(jsonString) as ExportCondition
      //     return japanese(exportCondition)
      //   } catch (e) {
      //     return jsonString
      //   }
      // }
    })

    const downloadFile = (item: UiSAsyncExportStatus) => {
      const link = document.createElement('a')
      link.download = `id_${item.operationId}_${props.type}.csv`
      // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
      link.href = item.getFileurlExported!
      link.click()
    }

    type State = {
      // locale(ja.json) の キー
      display: string
      // 処理が完了していれば true。成功/失敗を問わない
      isFinished: boolean
      // 処理が成功していれば true
      isSucceeded: boolean
    }

    const isStatusError = (value: string) => {
      for (const [k, v] of Object.entries(asyncStatusTypeDbMapping)) {
        if (k === value) {
          return v.isFinished && !v.isSucceeded
        }
      }
      return false
    }

    const displayStatus = computed(() => {
      return (item: Maybe<UiSAsyncExportStatus>) => {
        if (item?.status === undefined) {
          return $i18n.t('status.unknown')
        }
        if (asyncStatusTypeDbMapping[item.status] == null) {
          return $i18n.t('status.unknown') + '(' + item.status + ')'
        }
        const asyncStatusTypeDb = asyncStatusTypeDbMapping[item.status]
        const display = asyncStatusTypeDb.display
        if (display === 'status.errored') {
          return $i18n.t(display) + '(' + item.status + ')'
        } else {
          return $i18n.t(display)
        }
      }
    })

    const shouldShowLastModifiedDatetime = (
      node: Maybe<UiSAsyncExportStatus>
    ) => {
      const s = node?.status
      if (s === undefined) {
        return false
      }
      const status = asyncStatusTypeDbMapping[s]
      if (status === undefined) {
        return false
      }
      return status.isFinished && status.isSucceeded
    }

    const totalRowCount = () => {
      const rawValue = props.items?.edges?.length || 0
      return props.items?.pageInfo?.hasNextPage ? rawValue + 1 : rawValue
    }

    const row = () => {
      if (props.items?.edges === undefined) {
        return []
      }
      return props.items.edges.map((e, index) => {
        const label = e?.node?.label || ''
        return {
          index: index + 1,
          ...e?.node,
        }
      })
    }

    const onExtractInfoDisplay = (item: UiSAsyncExportStatus) => {
      context.emit('extract-info-display', item)
    }

    const rowColor = (item: any) => {
      if (item === null) {
        return []
      }
      const status = item.status
      if (isStatusError(status)) return 'table-danger'
    }
    const selectedRadio = props.selected
    const userRadioVal = props.userVal
    return {
      userRadioVal,
      selectedRadio,
      currentPage,
      fields,
      displayTime,
      displayParameter,
      displayScreenName,
      displayGraphqlQuery,
      displayStatus,
      onExtractInfoDisplay,
      downloadFile,
      onChangePage,
      onUpdateExportHistory,
      row,
      shouldShowLastModifiedDatetime,
      totalRowCount,
      rowColor,
    }
  },
})
