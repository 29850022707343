




















import { defineComponent, getCurrentInstance, ref } from '@vue/composition-api'
import { ApolloError, NetworkStatus } from 'apollo-client'
import dayjs from 'dayjs'

import ExportExtractInfoModal from '@/components/templates/h/ExportExtractInfoModal.vue'
import ExportView from '@/components/templates/h/ExportView.vue'
import {
  // ResourceType,
  // SettingHelperDocument,
  WhereOp,
  OrderByDirection,
  Where,
  UiSAsyncExportStatusesDocument,
  UiSAsyncExportStatusesQuery,
  UiSAsyncExportStatus,
} from '@/types/generated/graphql'
import { createApolloErrorMessage } from '@/utils/apiError'
import { setWhereValue, setWhereValues } from '@/utils/searchConditionDef'
import Toast, { genNotifyMessage } from '@/utils/toast'

export default defineComponent({
  components: { ExportView, ExportExtractInfoModal },
  layout: 'sidebar',
  apollo: {
    uiSAsyncExportStatuses: {
      query: UiSAsyncExportStatusesDocument,
      variables() {
        return {
          orderBy: [
            { field: 'createDatetime', direction: OrderByDirection.Desc },
          ],
          where: this._createWhere(),
          first: this.perPage,
        }
      },
      result(res, key) {
        this.ExportNetworkStatus = res.networkStatus
      },
      notifyOnNetworkStatusChange: true,
      error(e: ApolloError) {
        Toast.error(this, { message: createApolloErrorMessage(e) })
      },
    },
  },
  data() {
    return {
      userRadioVal: '1',
      selectedRadio: '1',
      extractCondition: {
        createUser: '1',
      },
      uiSAsyncExportStatuses: {
        edges: [],
        pageInfo: {
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: null,
          endCursor: null,
        },
      },
      // uiAsyncImportStatuses: {
      //   edges: [],
      //   pageInfo: {
      //     hasNextPage: false,
      //     hasPreviousPage: false,
      //     startCursor: null,
      //     endCursor: null,
      //   },
      // },
      loadedExPage: 1,
      // loadedImPage: 1,
      perPage: 20,
      ExportNetworkStatus: NetworkStatus.ready,
      // ImportNetworkStatus: NetworkStatus.ready,
      item: {} as UiSAsyncExportStatus,
    }
  },
  computed: {
    isLoading() {
      return this.ExportNetworkStatus !== NetworkStatus.ready
    },
  },
  methods: {
    /*********************************
     * エクスポート一覧用の処理
     *********************************/
    _createWhere() {
      // TODO: category0カラムが追加され次第抽出条件のbudgetGroupIdをcategory0に変更する
      const SEARCH_CONDITION: Where[] = [
        // 抽出条件
        {
          field: ['createUser'],
          op: WhereOp.In,
          value: [''],
        },
      ]
      const where = JSON.parse(JSON.stringify(SEARCH_CONDITION))
      let createUser = null
      if (this.extractCondition.createUser) {
        createUser = this.$store.state.auth.loginUserInfo.username
      }
      setWhereValue(where, 'createUser', createUser)
      console.log({ where })
      return where
    },

    async changePageExportHistory(newPage: number) {
      console.log('changePageExportHistory', { newPage })
      if (this.loadedExPage < newPage) {
        try {
          await this.fetchMoreExportHistory()
          this.loadedExPage = newPage
        } catch (e: unknown) {
          Toast.error(this, {
            message: genNotifyMessage('e.list', 'export', e as ApolloError),
          })
        }
      }
    },
    updateExportHistory() {
      this.loadedExPage = 1
      this.$apollo.queries.uiSAsyncExportStatuses.refetch()
    },
    async fetchMoreExportHistory() {
      await this.$apollo.queries.uiSAsyncExportStatuses.fetchMore({
        variables: {
          orderBy: [
            { field: 'createDatetime', direction: OrderByDirection.Desc },
          ],
          where: this._createWhere(),
          first: this.perPage,
          after: this.uiSAsyncExportStatuses.pageInfo.endCursor,
        },
        updateQuery: (
          previousResult: UiSAsyncExportStatusesQuery,
          { fetchMoreResult }: { fetchMoreResult: UiSAsyncExportStatusesQuery }
        ) => {
          return {
            uiSAsyncExportStatuses: {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              __typename: previousResult.uiSAsyncExportStatuses!.__typename,
              edges: [
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ...previousResult.uiSAsyncExportStatuses!.edges,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ...fetchMoreResult.uiSAsyncExportStatuses!.edges,
              ],
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              pageInfo: fetchMoreResult!.uiSAsyncExportStatuses!.pageInfo,
            },
          }
        },
      })
    },

    /*********************************
     * 抽出条件表示
     *********************************/
    onExtractInfoDisplay(item: UiSAsyncExportStatus) {
      // console.log(item)
      this.item = item
      this.$refs.exportExtractInfoModal.show()
    },
  },
})
